import React from 'react'

export default function () {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-16 h-16 relative"
      preserveAspectRatio="none"
    >
      <rect width="64" height="64" rx="32" fill="#D9F99D" />
      <path
        d="M33.75 23.25H47.75M47.75 23.25V37.25M47.75 23.25L33.75 37.25L26.75 30.25L16.25 40.75"
        stroke="#111827"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
